import React from 'react'
import PhysicianLandingPage from 'containers/physician-landing-page'

const UTSWLandingPage = () => (
  <PhysicianLandingPage
    physician="Dr. Bakare"
    institution="UT Southwestern"
    referralCode="UTSWTB"
    physicianURL="https://utswmed.org/doctors/tolulope-bakare/"
  />
)

export default UTSWLandingPage
